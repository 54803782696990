
export const setSection = section => ({
  type: 'NAVIGATE',
  section
})

export const setScrollSection = section => ({
  type: 'SCROLL_NAVIGATE',
  section  
})
export const setScore = value => ({
  type: 'SET_SCORE',
  value
})

export const setScrollSectionEnd = value => ({
  type: 'SCROLL_NAVIGATE_END'
})

export const increaseEnergy = value => ({
  type: 'INCREASE_ENERGY'
})

export const decreaseEnergy = value => ({
  type: 'DECREASE_ENERGY'
})