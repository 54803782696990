import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './contact.scss';
import { useEffect, useRef } from 'react';
import { useDispatch,connect } from 'react-redux';
import useOnScreen from '../../hooks/index';
import { setSection, setScore } from '../../actions'

function Contact(props) {

    const dispatch = useDispatch();
    const ref = useRef();
    const isOnScreen = useOnScreen(ref, '-100px');
    useEffect(() => {
      if (isOnScreen) {
         dispatch(setSection("contact"));
      }
    }, [isOnScreen]);
  


    const [status, setStatus] = useState("normal");
    const [selectedkey, setKey] = useState("");

    const keys = "abcdefghijklmnñopqrstuvwxyz.!1234567890_";

    const keyitems = keys.split("").map((key) =>
        <div className={`key  ${selectedkey === key ? "selected" : ""}`} key={key}>{key}</div>
    );
    const handleKey = (event) => {
        //console.log(event.key);
        //dispatch(setScore(5));
        props.setScore(5);
        setKey(event.key)
    }
    const getMessage = (message) =>{
        return <div className="message-box">
            {message}
        </div>
    }
    const getMessageRetry = (message) => {
        return <div className="message-box">
        {getMessage("Enter a valid email and a message...")}
        <div className="key send" onClick={doRetry}>RETRY</div>
        </div>
    }
    const getKeyboard = () =>{
        return <React.Fragment>
        {keyitems}
        <button className="key send" disabled={status === "confirmation"}>SEND</button>
    </React.Fragment>
    }
    const emailIsValid = (email) => {
        return /\S+@\S+\.\S+/.test(email)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const templateParams = {
            email: formData.get("email"),
            message: formData.get("message")
        };

        if (!emailIsValid(templateParams.email) || templateParams.message.length < 3) {
            setStatus("validationerror");
            return false;
        }
        setStatus("sending");

        emailjs.send('service_13cbnbk', 'template_01f9sw8', templateParams, 'user_JsoJxYpq3iMB61sZLYA4f')
            .then((response) => {
                setStatus("confirmation");
                console.log('SUCCESS!', response.status, response.text);
            }, (err) => {
                setStatus("error");
                console.log('FAILED...', err);
            });


    };
    
    const doRetry = () => {
        setStatus("normal");
    }

    return (
        <div ref={ref} id="contact" className="contact-container">
            <div className="header-contact">
            </div>
            <form onSubmit={handleSubmit}>
                <div className="container">
                    <div className="computer">

                        {status !== "confirmation" &&
                            <React.Fragment>
                                <input
                                    className="text-input"
                                    id="email"
                                    name="email"
                                    placeholder="EMAIL"
                                    maxLength={60}
                                    onKeyPress={handleKey}
                                />
                                <textarea
                                    className="text-input message"
                                    id="message"
                                    name="message"
                                    placeholder="MESSAGE"
                                    cols="24"
                                    rows="8"
                                    onKeyPress={handleKey}
                                />
                            </React.Fragment>
                        }
                        <span className="confirmation">
                            {status === "confirmation" && getMessage("Message Sent. Thanks!")}
                        </span>

                    </div>
                    <div className="keyboard">
                        {status === "sending" && getMessage("Sending your message....")}
                        {status === "validationerror" && getMessageRetry("Enter a valid email and a message...")}
                        {(status === "normal" || status === "confirmation") && getKeyboard()}
                    </div>
                    <div className="d-md-none"> 
                    {status === "validationerror" && getMessage("Enter a valid email and a message...")}
                    <button className="mobile-send" disabled={status === "confirmation"}>SEND</button>
                    </div>
                    <div className="social">
                        <a href="http://www.linkedin.com/in/davidbarrios/" className="icon linkedin" target="_blank" rel="noreferrer noopener"></a>
                        <a href="mailto:david.barrios@gmail.com" className="icon gmail" target="_blank" rel="noreferrer noopener"></a>
                    </div>
                </div>
            </form>
        </div>
    );

}

export default connect(null,{setScore})(Contact);



  