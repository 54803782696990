import React, { useEffect } from 'react';
import NavItem from './nav/nav-item';
import './home.scss';
import { useDispatch } from 'react-redux'
import { decreaseEnergy, increaseEnergy } from '../../actions'
import { connect } from 'react-redux'
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { useRef, useState, useSelector } from 'react';
import useOnScreen from '../../hooks/index';
import { setSection,setScore  } from '../../actions'

function Home(props) {
	// const dispatch = useDispatch();
	// let doClick = () => {
	// 	dispatch(decreaseEnergy());
	// }

	const dispatch = useDispatch();
	const ref = useRef();
	const isOnScreen = useOnScreen(ref, '-100px');
	useEffect(() => {
	  if (isOnScreen) {
		console.log("triggered home");
		dispatch(setSection("home"));
	  }
	}, [isOnScreen]);
	
	return (
		<React.Fragment>
			<div className="header-background"></div>
			<div ref={ref} className="home-container">
				<div className="container">
					<div className="row">
						<div className="home">
							<div onClick={()=> dispatch(increaseEnergy())} className="title">
							<div className="name">David Barrios</div>	
    					</div>
							<div className="home-nav">
								<NavItem label="about"></NavItem>
								<NavItem label="contact"></NavItem>
							</div>
							<div className="trademark">
								TM AND © DAVID BARRIOS 2021
    					</div>
						</div>
					</div>
				</div>

			</div>
			<div className="home-city"></div>
		</React.Fragment>
	)
}
const mapDispatchToProps = (dispatch) => {
	return {
	  // dispatching plain actions
	  increment: () => dispatch({ type: 'INCREMENT' }),
	  decrement: () => dispatch({ type: 'DECREMENT' }),
	}
  }
export default connect(null, mapDispatchToProps)(Home);