import React from 'react';
import { default as Header } from '../header';
import { default as Home } from '../home';
import { default as About } from '../about';
import { default as Contact } from '../contact';
import { default as Footer } from '../footer';
import './App.scss';
import { setSection, increaseEnergy, decreaseEnergy } from "../../actions/index";
import useOnScreen from '../../hooks/index';
import { useEffect,useRef, useState, useSelector } from 'react';
import { scroller , Events} from 'react-scroll'
import { connect } from 'react-redux'
import { useDispatch } from 'react-redux'
import { setScrollSectionEnd,setScore  } from '../../actions'

function doScroll(section) {
  if (section === "home") {
    section = "header";
  }
  scroller.scrollTo(section, {
    duration: 1000,
    delay: 0,
    smooth: true,
    offset: section === "about"?-50:0
  });

  
}

function App(props) {
  const dispatch = useDispatch();
  Events.scrollEvent.register('end',  () => {
    dispatch(setScore(500));
    dispatch(setScrollSectionEnd());
  });

  useEffect(() => {
    doScroll(props.scrollToSection);
    return () => {
    };
  },[props.scrollToSection]);

  useEffect(() => {
    dispatch(setScore(50));
    return () => {
    };
  },[props.section]);

  return (
    <div className="app">
      <Header></Header>
      <Home></Home>
      <About></About>
      <Contact></Contact>
      <Footer></Footer>
    </div>
  );
}

const mapStateToProps = state => ({ section: state.navigation.currentSection, scrollToSection: state.navigation.scrollToSection })



export default connect(mapStateToProps, { setSection, increaseEnergy, decreaseEnergy })(App);
