import React, {useEffect,useState} from 'react';
import {useSelector} from 'react-redux'
import EnergyBar from './energybar.js';
import {sleep} from '../../utils/utils'
import './header.scss';
function Header (props){
		const [levelchanging, setLevelChanging] = useState(false);
		const [scorechanging, setScoreChanging] = useState(false);
		
		const energy = useSelector(state => state.status.energy);
		const score = useSelector(state => state.status.score);
		const section = useSelector(state => state.navigation.currentSection);

		let level = section === "home"? 1: section ==="about"? 2:3;
		let toggleBlink = async () => {
			setLevelChanging(true);
			await sleep(1000);
			setLevelChanging(false);
		};
		let toggleBlinkScore = async () => {
			setScoreChanging(true);
			await sleep(1000);
			setScoreChanging(false);
		};
	
		useEffect(() => {
			toggleBlink();
			console.log("level updated!");
		}, [section]);

		  useEffect(() => {
			toggleBlinkScore();
			console.log("score updated!");
		  }, [score]);

		return (<div id="header" className="header-container">
				<div className="container">
					<div className="row">
						<div className="header">
							<div className="info level">
								<div>level {level}</div>
								<div className={`${levelchanging ? "blinking" : ""}`}>{section}</div>
							</div>
							<div className="info score">
								<div>Hi Score</div>
								<div className={`${scorechanging ? "blinking" : ""}`}>{score}</div>
							</div>
							<div className="info energy">
								<div>Energy</div>
								<div><EnergyBar energy={energy}/></div>
							</div>
						</div>
					</div>
				</div>
			
		</div>);
	
}
export default Header;