import React from 'react';
import {useSelector} from 'react-redux'
import './energybar.scss';
import heart from '../../assets/images/heart.gif'
function EnergyBar (props){

	return (
       <div>
        {[...Array(props.energy)].map((value, index)=>{
               return <img src={heart} key={index} alt="heart"/>
        })}
        </div>
    );	
}
export default EnergyBar;