const statusReducer = (state = {energy:5, score:100},action)=> {
    switch (action.type) {  
        case 'INCREASE_ENERGY':
            return {energy: state.energy + 1, score: state.score};
        case 'DECREASE_ENERGY':
            return {energy: state.energy + 1, score: state.score};
        case 'SET_SCORE':
            return {energy: state.energy, score: state.score + action.value};
        
        default:
            return state
    }
}

export default statusReducer;