const navigationReducer = (state = { currentSection: "home", isScrolling: true, scrollToSection:"" }, action) => {
  switch (action.type) {
    case 'NAVIGATE':
      return { currentSection: action.section }
    case 'SCROLL_NAVIGATE':
      return { isScrolling: true, scrollToSection: action.section , currentSection: state.currentSection}
    case 'SCROLL_NAVIGATE_END':
      return { isScrolling: false, scrollToSection: "", currentSection: state.currentSection}
  
      default:
      return state
  }
}

export default navigationReducer;