import React from 'react';
import './about.scss';
import { useEffect, useRef, useState, useSelector } from 'react';
import { useDispatch } from 'react-redux';
import useOnScreen from '../../hooks/index';
import { setSection } from '../../actions'

function About() {
  const dispatch = useDispatch();
  const ref = useRef();
  const isOnScreen = useOnScreen(ref, '-100px');
  useEffect(() => {
    if (isOnScreen) {
      console.log("triggered about");
      dispatch(setSection("about"));
    }
  }, [isOnScreen]);

  return (
    <React.Fragment>
      <div ref={ref} name="about" className="about-container">
        <div className="inner-about">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <span>I am a Front-end Architect with over 17 years of experience. I am currently a Tech Lead, managing teams of web developers and leading the architecture and frontend implementation of large scale projects.</span>
              </div>
            </div>

            <div className="content">
              <div className="left">
                <h3>SKILLS</h3>
                <ul>
                  <li>React............ OK</li>
                  <li>VueJS............ OK</li>
                  <li>Angular.......... OK</li>
                  <li>HTML/CSS/SASS ... OK</li>
                  <li>C#.NET .......... OK</li>
                  <li>PHP ............. NO</li>
                  <li>Actionscript ... RIP</li>

                </ul>
                <h3>WORK</h3>
                <ul>
                  <li>AVANADE.... 2015-NOW</li>
                  <li>VML........ 2013-2015</li>
                  <li>STUDIOCOM.. 2007-2013</li>
                  <li>CALLTECH... 2003-2006</li>
                </ul>
                <h3>EDUCATION</h3>
                <ul>
                  <li>U.DISTRITAL(BCS) 2010</li>  
                </ul>
                
              </div>
              <div className="right">
              <h3>PLACES</h3>
                <ul>
                  <li>ATLANTA.... 2012-NOW</li>
                  <li>BOGOTA.... 1981-2011</li>
                </ul>

                <h3>LIFE</h3>
                <ul>
                  <li>COFFEE........... OK</li>
                  <li>MUSIC............ OK</li>
                  <li>BOXING........... OK</li>
                  <li>PATIENCE......... NO</li>
                  <li><a href="http://www.945bit.com" target="_blank">PIXEL ART</a> ....... OK</li>
                  <li>AWARDS........... NO</li>
                </ul>
    
                <h3>LANGUAGES</h3>
                <ul>
                  <li>Spanish.......... SI</li>
                  <li>English.......... OK</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mountains"></div>
      </div>
    </React.Fragment>
  )
}
export default About;