import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { setScrollSection } from '../../../actions'
import {sleep} from '../../../utils/utils'

function NavItem(props) {
	const dispatch = useDispatch();
	const [selected, setSelected] = useState(false);
	useEffect(() => {

	});
	
	let doClick = async (e) => {
		setSelected(true);
		await sleep(2000);
		setSelected(false);
		dispatch(setScrollSection(props.label));
	};
	return (
		<div className={`nav-item ${selected ? "blinking" : ""}`} onClick={doClick}>{props.label}</div>
	)
}
export default NavItem;